<template>
  <div>
    <div class="preview-nav">
      <div class="bb-container preview-nav__container">
        <div class="preview-nav__left">
          <a @click.prevent="close()">
            <div class="preview-nav__back">
              <img
                style="display: none; "
                src="../../assets/img/ico/ico-arrow-back.svg"
                loading="lazy"
                alt="Volver"
                class="preview-nav__arrow preview-nav__arrow--back"
              />
            </div>
          </a>
        </div>
        <div class="preview-nav__right">
          <a @click.prevent="close()">
            <div class="preview-nav__close">
              <img
                src="../../assets/img/ico/ico-close.svg"
                loading="lazy"
                alt="Volver"
                class="preview-nav__arrow preview-nav__close"
              />
            </div>
          </a>
        </div>
      </div>
    </div>

    <main class="pad-b-3">
      <div class="bb-container bb-container--blog">
        <article class="post mar-b-4">
          <h1 class="post__h1">
            {{ post.title }}
          </h1>
          <div class="post__meta">
            <time class="post__date">{{ published_date }}</time>

            <div class="post__meta-separa">·</div>

            <div class="post__tags">
              <a
                :href="'/blog/categorias/' + category.slug"
                :key="category.id"
                v-for="(category, index) in categories"
                >{{ category.name }}
                <span v-if="!(index === categories.length - 1)"> | </span></a
              >
            </div>
          </div>
          <div class="post__thumbnail">
            <img
              :src="post.feature_image"
              :alt="post.feature_image_alt"
              loading="lazy"
            />
          </div>
          <div class="post__entry">
            <div class="post-entry" v-html="post.html" />
          </div>
        </article>
      </div>
    </main>
  </div>
</template>
<script>
import BlogPostThumb from "../blog/components/BlogPostThumb.vue";
import CtaPost from "../cta/CtaPost.vue";
import { mapGetters } from "vuex";

export default {
  name: "BlogArticle",
  components: {
    BlogPostThumb,
    CtaPost,
  },
  metaInfo() {
    return {
      title: `${this.post.title} | HelloBB`,
    };
  },
  computed: {
    ...mapGetters({
      post: "post",
    }),
    category() {
      return this.post.primary_tag?.name;
    },
    categories() {
      return this.post.tags;
    },
    published_date() {
      return this.post.published_at
        ? this.$utils.formatDate(this.post.published_at)
        : "";
    },
  },
  async serverPrefetch() {
    return this.getPost();
  },
  methods: {
    getPost() {
      return this.$store.dispatch("getPost", this.$route.params.slug);
    },
    close() {
      this.$store.commit("setPost", {});
      this.$router.back()
    }
  },
  mounted() {
    if (this.$utils.isEmpty(this.post)) {
      return this.$store.dispatch("getPost", this.$route.params.slug);
    }
  },
};
</script>
